import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import { isIOS, isMobile } from 'react-device-detect';

import { signOut } from 'actions/Auth';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import { hasPermission, isExpired } from 'utils/auth'
import asyncComponent from 'utils/asyncComponent';

import appRoutes from './routes';

const translateRoutesToNode = ({ root, routes, authUser }) => {
	const nodes = [];
	routes.forEach(route => {
		if (authUser && route && hasPermission(authUser, route.permission)) {
			let fullPath = route.path;
			if (route.params) {
				let paramsPath = '';
				route.params.forEach(param => {
					let currentPath = `/:${param.name}`;
					if (param.optional) currentPath = `${currentPath}?`;
					paramsPath = `${paramsPath}${currentPath}`;
				});
				fullPath = `${fullPath}${paramsPath}`;
			}
			nodes.push(
				<Route
					authUser={authUser}
					path={`${root}/${fullPath}`}
					exact={!route.greedy}
					key={`${root}/${fullPath}`}
					component={asyncComponent(() =>
						import(/* webpackMode: "eager" */ `${route.component}`)
					)}
				/>
			);
			
			if (route.children) {
				nodes.push(
					translateRoutesToNode({
						root: `${root}/${route.path}`,
						routes: route.children,
						authUser,
					})
				);
			}
		}
	});
	return nodes;
};

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			routes: [],
		};
	}

	componentDidMount() {
		this.updateRoutes(this.props.authUser, this.props.match.url);
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.authUser !== this.props.authUser ||
			nextProps.location !== this.props.location
		) {
			this.updateRoutes(nextProps.authUser, nextProps.match.url);
		}
	}

	updateRoutes(authUser, root) {
		this.setState({
			routes: translateRoutesToNode({
				root,
				routes: appRoutes,
				authUser,
			}),
		});
	}

	render() {
		const { drawerType, fullScreen, authUser, showFooter } = this.props;
		const gandauthToken = localStorage.getItem('gandauthToken');

		if (!authUser) {
			return <Redirect to="/login" />;
		}

		if (isExpired(gandauthToken)) {
			this.props.signOut({ expired: true });
		}

		const drawerStyle = drawerType.includes(FIXED_DRAWER)
			? 'fixed-drawer'
			: drawerType.includes(COLLAPSED_DRAWER)
				? 'collapsible-drawer'
				: 'mini-drawer';
		// set default height and overflow for iOS mobile Safari 10+ support.
		if (isIOS && isMobile) {
			$('#body').addClass('ios-mobile-view-height');
		} else if ($('#body').hasClass('ios-mobile-view-height')) {
			$('#body').removeClass('ios-mobile-view-height');
		}
		if (fullScreen) {
			$('#body').addClass('fullscreen');
		} else {
			$('#body').removeClass('fullscreen');
		}
		return (
			<div className={`app-container ${drawerStyle}`}>
				<Sidebar routes={this.state.routes}/>
				<div className="app-main-container">
					<div className="app-header">
						<Header />
					</div>

					<main className="app-main-content-wrapper">
						<div className="app-main-content">{this.state.routes}</div>
						{showFooter && <Footer />}
					</main>
				</div>
			</div>
		);
	}
}

App.propTypes = {
	drawerType: PropTypes.string,
};

App.defaultProps = {
	drawerType: '',
};

const mapStateToProps = ({ settings, auth }) => ({
	drawerType: settings.drawerType,
	fullScreen: settings.fullScreen,
	authUser: auth.authUser,
	showFooter: settings.showFooter,
});

const mapActionsToProps = {
  	signOut,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
