import {
	SIGNIN_REQUEST,
	SIGNIN_RESPONSE,
	SIGNIN_ERROR,
	SIGNOUT_REQUEST,
	SIGNOUT_RESPONSE,
	SIGNOUT_ERROR,
} from 'constants/ActionTypes';

import { errorNotification, sendNotification } from './Notifications';

export const signIn = ({ email, password }) => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: SIGNIN_REQUEST,
		});
		const response = await api.post('auth/login/', {
			email,
			password,
		});
		localStorage.setItem('user', JSON.stringify(response.data.user));
		localStorage.setItem('authToken', response.data.token);
		localStorage.setItem('gandauthToken', response.data.gandauthToken);
		localStorage.setItem('gandauthRefreshToken', response.data.gandauthRefreshToken);

		dispatch({
			type: SIGNIN_RESPONSE,
			payload: response.data.user,
		});
	} catch (err) {
		dispatch({
			type: SIGNIN_ERROR,
			payload: err,
		});
		dispatch(errorNotification(err));
	}
};

export const signOut = (options = {}) => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: SIGNOUT_REQUEST,
		});
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		dispatch({
			type: SIGNOUT_RESPONSE,
		});

		const { expired } = options;
		if (expired) {
			dispatch(sendNotification({
				type: 'info',
				message: 'Sessão expirada. Faça login novamente.'
			}));
		}
	} catch (err) {
		dispatch({
			type: SIGNOUT_ERROR,
			payload: err,
		});
		dispatch(errorNotification(err));
	}
};
