export const PickingTypes = {
  frozen: "frozen",
  snacks: "snacks",
  basket: "basket",
  greenGrocer: "greenGrocer",
};
export const hasType = (productType) => (sale) =>
  sale.cart.product_types.includes(productType);

export const hasFrozen = hasType(PickingTypes.frozen);
export const hasSnacks = hasType(PickingTypes.snacks);
export const hasBasket = hasType(PickingTypes.basket);
export const hasGreenGrocer = hasType(PickingTypes.greenGrocer);

export const finishedPicking = (pickingType) => (sale) =>
  sale.logistics.picking[pickingType].end;

export const finishedFrozenPicking = finishedPicking(PickingTypes.frozen);
export const finishedSnacksPicking = finishedPicking(PickingTypes.snacks);
export const finishedBasketPicking = finishedPicking(PickingTypes.basket);
export const finishedGreenGrocerPicking = finishedPicking(
  PickingTypes.greenGrocer
);

export const PickingTypesByDispatchType = {
  frozen: [PickingTypes.frozen, PickingTypes.snacks],
  greenGrocer: [PickingTypes.greenGrocer],
};

export const PackageCodesByDispatchType = {
  frozen: ["F", "S"],
  greenGrocer: ["G"],
};

export const hasDispatch = (dispatchType) => (sale) =>
  PickingTypesByDispatchType[dispatchType].some((pickingType) =>
    hasType(pickingType)(sale)
  );

export const finishedDispatch = (dispatchType) => (sale) =>
  sale.logistics[dispatchType] && sale.logistics[dispatchType].end;

export const hasPackages = (order, pickingTypes) => {
  if (!order?.packing) {
    return false;
  }

  const packagesCount = Object.entries(order.packing).reduce(
    (acc, [packingKind, packing]) => {
      if (pickingTypes.includes(packingKind)) {
        return acc + packing.packages.length;
      }
      return acc;
    },
    0
  )

  return packagesCount > 0;
}

export const fixOrder = (order, pickingTypes) => {
  if (hasPackages(order, pickingTypes) || !pickingTypes.includes('frozen')) {
    return order;
  }
  return {
    ...order,
    totalPacks: {
      ...order.totalPacks,
      frozen: { checks: 0, number: 1 },
    },
    packing: {
      ...order.packing,
      frozen: {
        ...order.packing.frozen,
        printingError: true,
        packages: order.packing.frozen.packages.concat([
          {
            _id: '0',
            local: 'Sacola não posicionada',
            checked: false,
          },
        ]),
      },
    },
  };
}
