// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const TOGGLE_DISABLED_NAV = 'toggle_disabled_nav';
export const TOGGLE_FULL_SCREEN = 'toggle_full_screen';
export const TOGGLE_DRAWER_TYPE = 'toggle_drawer_type';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';

// Auth const
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_RESPONSE = 'SIGNIN_RESPONSE';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SIGNOUT_RESPONSE = 'SIGNOUT_RESPONSE';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

// DistributionCenters constants
export const DISTRIBUTION_CENTERS_FETCH_REQUEST = 'DISTRIBUTION_CENTERS_FETCH_REQUEST';
export const DISTRIBUTION_CENTERS_FETCH_RESPONSE = 'DISTRIBUTION_CENTERS_FETCH_RESPONSE';
export const DISTRIBUTION_CENTERS_FETCH_ERROR = 'DISTRIBUTION_CENTERS_FETCH_ERROR';
export const DISTRIBUTION_CENTERS_SELECT = 'DISTRIBUTION_CENTERS_SELECT';

// Periods constants
export const PERIODS_FETCH_REQUEST = 'PERIODS_FETCH_REQUEST';
export const PERIODS_FETCH_RESPONSE = 'PERIODS_FETCH_RESPONSE';
export const PERIODS_FETCH_ERROR = 'PERIODS_FETCH_ERROR';
export const PERIOD_SELECT = 'PERIOD_SELECT';

// Route types constants
export const ROUTE_TYPES_FETCH_REQUEST = 'ROUTE_TYPES_FETCH_REQUEST';
export const ROUTE_TYPES_FETCH_RESPONSE = 'ROUTE_TYPES_FETCH_RESPONSE';
export const ROUTE_TYPES_FETCH_ERROR = 'ROUTE_TYPES_FETCH_ERROR';
export const ROUTE_TYPE_SELECT = 'ROUTE_TYPE_SELECT';

// DeliveryRoutes constants
export const UPLOAD_ROUTES_REQUEST = 'UPLOAD_ROUTES_REQUEST';
export const UPLOAD_ROUTES_RESPONSE = 'UPLOAD_ROUTES_RESPONSE';
export const UPLOAD_ROUTES_ERROR = 'UPLOAD_ROUTES_ERROR';
export const DELIVERY_ROUTES_PERIODS_REQUEST = 'DELIVERY_ROUTES_PERIODS_REQUEST';
export const DELIVERY_ROUTES_PERIODS_RESPONSE = 'DELIVERY_ROUTES_PERIODS_RESPONSE';
export const DELIVERY_ROUTES_PERIODS_ERROR = 'DELIVERY_ROUTES_PERIODS_ERROR';
export const GET_ROUTES_REQUEST = 'GET_ROUTES_REQUEST';
export const GET_ROUTES_RESPONSE = 'GET_ROUTES_RESPONSE';
export const GET_ROUTES_ERROR = 'GET_ROUTES_ERROR';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const CHECK_UPDATE_ROUTE = 'CHECK_UPDATE_ROUTE';
export const DELETE_ROUTE_REQUEST = 'DELETE_ROUTE_REQUEST';
export const DELETE_ROUTE_RESPONSE = 'DELETE_ROUTE_RESPONSE';
export const DELETE_ROUTE_ERROR = 'DELETE_ROUTE_ERROR';
export const CALL_LOGGI_REQUEST = 'CALL_LOGGI_REQUEST';
export const CALL_LOGGI_RESPONSE = 'CALL_LOGGI_RESPONSE';
export const CALL_LOGGI_ERROR = 'CALL_LOGGI_ERROR';
export const SIMULATE_ROUTE_REQUEST = 'SIMULATE_ROUTE_REQUEST';
export const SIMULATE_ROUTE_RESPONSE = 'SIMULATE_ROUTE_RESPONSE';
export const SIMULATE_ROUTE_ERROR = 'SIMULATE_ROUTE_ERROR';
export const RESET_REPEATED_ROUTES = 'RESET_REPEATED_ROUTES';
export const CHANGE_ROUTE_TYPE_REQUEST = 'CHANGE_ROUTE_TYPE_REQUEST';
export const CHANGE_ROUTE_TYPE_RESPONSE = 'CHANGE_ROUTE_TYPE_RESPONSE';
export const CHANGE_ROUTE_TYPE_ERROR = 'CHANGE_ROUTE_TYPE_ERROR';
export const TOGGLE_EXTRA_RETURN_REQUEST = 'TOGGLE_EXTRA_RETURN_REQUEST';
export const TOGGLE_EXTRA_RETURN_RESPONSE = 'TOGGLE_EXTRA_RETURN_RESPONSE';
export const TOGGLE_EXTRA_RETURN_ERROR = 'TOGGLE_EXTRA_RETURN_ERROR';

// WMS Snacks constants
export const SNACKS_POST_CONTAINER_REQUEST = 'SNACKS_POST_CONTAINER_REQUEST';
export const SNACKS_POST_CONTAINER_RESPONSE = 'SNACKS_POST_CONTAINER_RESPONSE';
export const SNACKS_POST_CONTAINER_ERROR = 'SNACKS_POST_CONTAINER_ERROR';
export const SNACKS_RESET_CONTAINER_STATE = 'SNACKS_RESET_CONTAINER_STATE';
export const SNACKS_POST_CONTAINER_CHECK = 'SNACKS_POST_CONTAINER_CHECK';

// WMS Frozen consta_ts
export const FROZEN_POST_CONTAINER_REQUEST = 'FROZEN_POST_CONTAINER_REQUEST';
export const FROZEN_POST_CONTAINER_RESPONSE = 'FROZEN_POST_CONTAINER_RESPONSE';
export const FROZEN_POST_CONTAINER_ERROR = 'FROZEN_POST_CONTAINER_ERROR';
export const FROZEN_RESET_CONTAINER_STATE = 'FROZEN_RESET_CONTAINER_STATE';
export const FROZEN_POST_CONTAINER_CHECK = 'FROZEN_POST_CONTAINER_CHECK';

// WMS GreenGrocer constants
export const GREEN_GROCER_POST_CONTAINER_REQUEST =
  'GREEN_GROCER_POST_CONTAINER_REQUEST';
export const GREEN_GROCER_POST_CONTAINER_RESPONSE =
  'GREEN_GROCER_POST_CONTAINER_RESPONSE';
export const GREEN_GROCER_POST_CONTAINER_ERROR =
  'GREEN_GROCER_POST_CONTAINER_ERROR';
export const GREEN_GROCER_RESET_CONTAINER_STATE =
  'GREEN_GROCER_RESET_CONTAINER_STATE';
export const GREEN_GROCER_POST_CONTAINER_CHECK =
  'GREEN_GROCER_POST_CONTAINER_CHECK';

// Notification constans
export const NOTIFICATION_SEND = 'NOTIFICATION_SEND';
export const NOTIFICATION_RESET = 'NOTIFICATION_RESET';

// Dispatch constans
export const DISPATCH_FINISH_REQUEST = 'DISPATCH_FINISH_REQUEST';
export const DISPATCH_FINISH_RESPONSE = 'DISPATCH_FINISH_RESPONSE';
export const DISPATCH_FINISH_ERROR = 'DISPATCH_FINISH_ERROR';
export const DISPATCH_FINISH_RESET_STATUS = 'DISPATCH_FINISH_RESET_STATUS';
export const DISPATCH_LOAD_ROUTE_REQUEST = 'DISPATCH_LOAD_ROUTE_REQUEST';
export const DISPATCH_LOAD_ROUTE_ERROR = 'DISPATCH_LOAD_ROUTE_ERROR';
export const DISPATCH_LOAD_ROUTE_RESPONSE = 'DISPATCH_LOAD_ROUTE_RESPONSE';
export const DISPATCH_AVAILABLE_PERIODS_REQUEST = 'DISPATCH_AVAILABLE_PERIODS_REQUEST';
export const DISPATCH_AVAILABLE_PERIODS_RESPONSE = 'DISPATCH_AVAILABLE_PERIODS_RESPONSE';
export const DISPATCH_AVAILABLE_PERIODS_ERROR = 'DISPATCH_AVAILABLE_PERIODS_ERROR';
export const DISPATCH_CLEAR_LAST_ROUTES = 'DISPATCH_CLEAR_LAST_ROUTES';
export const DISPATCH_PRINT_NFCE_REQUEST = 'DISPATCH_PRINT_NFCE_REQUEST';
export const DISPATCH_PRINT_NFCE_RESPONSE = 'DISPATCH_PRINT_NFCE_RESPONSE';
export const DISPATCH_PRINT_NFCE_ERROR = 'DISPATCH_PRINT_NFCE_ERROR';

// ProductType constants
export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE';

// Orders List Container
export const GET_ORDERS_LIST_REQUEST = 'GET_ORDERS_LIST_REQUEST';
export const GET_ORDERS_LIST_RESPONSE = 'GET_ORDERS_LIST_RESPONSE';
export const GET_ORDERS_LIST_ERROR = 'GET_ORDERS_LIST_ERROR';

// RoutingAPI
export const RUN_ROUTER_REQUEST = 'RUN_ROUTER_REQUEST';
export const RUN_ROUTER_RESPONSE = 'RUN_ROUTER_RESPONSE';
export const RUN_ROUTER_ERROR = 'RUN_ROUTER_ERROR';
export const FETCH_ROUTER_STATUS_REQUEST = 'FETCH_ROUTER_STATUS_REQUEST';
export const FETCH_ROUTER_STATUS_RESPONSE = 'FETCH_ROUTER_STATUS_RESPONSE';
export const FETCH_ROUTER_STATUS_ERROR = 'FETCH_ROUTER_STATUS_ERROR';

// ProductsLoss constants
export const PRODUCTS_LOSS_FINISH_REQUEST = 'PRODUCTS_LOSS_FINISH_REQUEST';
export const PRODUCTS_LOSS_FINISH_RESPONSE = 'PRODUCTS_LOSS_FINISH_RESPONSE';
export const PRODUCTS_LOSS_FINISH_ERROR = 'PRODUCTS_LOSS_FINISH_ERROR';
export const PRODUCTS_LOSS_ERROR_CLEAR = 'PRODUCTS_LOSS_ERROR_CLEAR';
export const PRODUCTS_LOSS_RESET = 'PRODUCTS_LOSS_RESET';

// OrdersReturn
export const ORDERS_RETURN_FINISH_REQUEST = 'ORDERS_RETURN_FINISH_REQUEST';
export const ORDERS_RETURN_FINISH_RESPONSE = 'ORDERS_RETURN_FINISH_RESPONSE';
export const ORDERS_RETURN_FINISH_ERROR = 'ORDERS_RETURN_FINISH_ERROR';
export const ORDERS_RETURN_GET_ORDER_REQUEST = 'ORDERS_RETURN_GET_ORDER_REQUEST';
export const ORDERS_RETURN_GET_ORDER_RESPONSE = 'ORDERS_RETURN_GET_ORDER_RESPONSE';
export const ORDERS_RETURN_GET_ORDER_ERROR = 'ORDERS_RETURN_GET_ORDER_ERROR';

// TransferCheck
export const TRANSFER_CHECK_FETCH_INVENTORY_ORDER_REQUEST =
  'TRANSFER_CHECK_FETCH_INVENTORY_ORDER_REQUEST';
export const TRANSFER_CHECK_FETCH_INVENTORY_ORDER_RESPONSE =
  'TRANSFER_CHECK_FETCH_INVENTORY_ORDER_RESPONSE';
export const TRANSFER_CHECK_FETCH_INVENTORY_ORDER_ERROR =
  'TRANSFER_CHECK_FETCH_INVENTORY_ORDER_ERROR';
export const TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_REQUEST =
  'TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_REQUEST';
export const TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_RESPONSE =
  'TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_RESPONSE';
export const TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_ERROR =
  'TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_ERROR';
export const TRANSFER_CHECK_FINISH_REQUEST = 'TRANSFER_CHECK_FINISH_REQUEST';
export const TRANSFER_CHECK_FINISH_RESPONSE = 'TRANSFER_CHECK_FINISH_RESPONSE';
export const TRANSFER_CHECK_FINISH_ERROR = 'TRANSFER_CHECK_FINISH_ERROR';
export const TRANSFER_CHECK_STORE_RESET = 'TRANSFER_CHECK_STORE_RESET';
export const TRANSFER_CHECK_FETCH_DC_ORDERS_REQUEST = 'TRANSFER_CHECK_FETCH_DC_ORDERS_REQUEST';
export const TRANSFER_CHECK_FETCH_DC_ORDERS_RESPONSE = 'TRANSFER_CHECK_FETCH_DC_ORDERS_RESPONSE';
export const TRANSFER_CHECK_FETCH_DC_ORDERS_ERROR = 'TRANSFER_CHECK_FETCH_DC_ORDERS_ERROR';
export const TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_REQUEST = 'TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_REQUEST';
export const TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_RESPONSE = 'TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_RESPONSE';
export const TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_ERROR = 'TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_ERROR';
export const TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_REQUEST =
  'TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_REQUEST';
export const TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_RESPONSE =
  'TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_RESPONSE';
export const TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_ERROR =
  'TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_ERROR';
export const TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_REQUEST =
  'TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_REQUEST';
export const TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_RESPONSE =
  'TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_RESPONSE';
export const TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_ERROR =
  'TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_ERROR';

// FiscalPrinting
export const FISCAL_PRINTING_REQUEST = 'FISCAL_PRINTING_REQUEST';
export const FISCAL_PRINTING_RESPONSE = 'FISCAL_PRINTING_RESPONSE';
export const FISCAL_PRINTING_ERROR = 'FISCAL_PRINTING_ERROR';
export const FISCAL_PRINTING_INVOICE_REQUEST =
  'FISCAL_PRINTING_INVOICE_REQUEST';
export const FISCAL_PRINTING_INVOICE_RESPONSE =
  'FISCAL_PRINTING_INVOICE_RESPONSE';
export const FISCAL_PRINTING_INVOICE_ERROR = 'FISCAL_PRINTING_INVOICE_ERROR';

// ProductsReturn
export const PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST =
  'PRODUCTS_RETURN_CONFIRM_RETURN_RODUCT_REQUEST';
export const PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE =
  'PRODUCTS_RETURN_CONFIRM_RETURN_RODUCT_RESPONSE';
export const PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR =
  'PRODUCTS_RETURN_CONFIRM_RETURN_RODUCT_ERROR';

// ProductsInfo
export const PRODUCTS_INFO_REQUEST = 'PRODUCTS_INFO_REQUEST';
export const PRODUCTS_INFO_RESPONSE = 'PRODUCTS_INFO_RESPONSE';
export const PRODUCTS_INFO_ERROR = 'PRODUCTS_INFO_ERROR';
export const PRODUCTS_INFO_ERROR_CLEAR = 'PRODUCTS_INFO_ERROR_CLEAR';

// InventoryInput
export const GET_PALLETS_REQUEST = 'GET_PALLETS_REQUEST';
export const GET_PALLETS_RESPONSE = 'GET_PALLETS_RESPONSE';
export const GET_PALLETS_ERROR = 'GET_PALLETS_ERROR';
export const ADD_BOX_TO_PALLET_REQUEST = 'ADD_BOX_TO_PALLET_REQUEST';
export const ADD_BOX_TO_PALLET_RESPONSE = 'ADD_BOX_TO_PALLET_RESPONSE';
export const ADD_BOX_TO_PALLET_ERROR = 'ADD_BOX_TO_PALLET_ERROR';
export const RESET_INVENTORY_INPUT_STORE = 'RESET_INVENTORY_INPUT_STORE';

// Close Pallets
export const SET_PALLET = 'SET_PALLET';
export const CLOSE_PALLET_REQUEST = 'CLOSE_PALLET_REQUEST';
export const CLOSE_PALLET_RESPONSE = 'CLOSE_PALLET_RESPONSE';
export const CLOSE_PALLET_ERROR = 'CLOSE_PALLET_ERROR';
export const RESET_CLOSE_PALLETS_STORE = 'RESET_CLOSE_PALLETS_STORE';

export const GET_COUNTING_REQUEST = 'GET_COUNTING_REQUEST';
export const GET_COUNTING_RESPONSE = 'GET_COUNTING_RESPONSE';
export const GET_COUNTING_ERROR = 'GET_COUNTING_ERROR';

export const SAVE_COUNTING_REQUEST = 'SAVE_COUNTING_REQUEST';
export const SAVE_COUNTING_RESPONSE = 'SAVE_COUNTING_RESPONSE';
export const SAVE_COUNTING_ERROR = 'SAVE_COUNTING_ERROR';

export const FINISH_COUNTING_REQUEST = 'FINISH_COUNTING_REQUEST';
export const FINISH_COUNTING_RESPONSE = 'FINISH_COUNTING_RESPONSE';
export const FINISH_COUNTING_ERROR = 'FINISH_COUNTING_ERROR';

export const CALCULATE_INVENTORY_REQUEST = 'CALCULATE_INVENTORY_REQUEST';
export const CALCULATE_INVENTORY_RESPONSE = 'CALCULATE_INVENTORY_RESPONSE';
export const CALCULATE_INVENTORY_ERROR = 'CALCULATE_INVENTORY_ERROR';

export const CLEAR_COUNTING_STORE = 'CLEAR_COUNTING_STORE';

export const INSERT_GREENGROCER_REQUEST = 'INSERT_GREENGROCER_REQUEST'
export const INSERT_GREENGROCER_RESPONSE = 'INSERT_GREENGROCER_RESPONSE'
export const INSERT_GREENGROCER_ERROR = 'INSERT_GREENGROCER_ERROR'


// Manage Pallets
export const GET_CLOSED_PALLETS_REQUEST = 'GET_CLOSED_PALLETS_REQUEST';
export const GET_CLOSED_PALLETS_RESPONSE = 'GET_CLOSED_PALLETS_RESPONSE';
export const GET_CLOSED_PALLETS_ERROR = 'GET_CLOSED_PALLETS_ERROR';

export const DELETE_PALLET_REQUEST = 'DELETE_PALLET_REQUEST';
export const DELETE_PALLET_RESPONSE = 'DELETE_PALLET_RESPONSE';
export const DELETE_PALLET_ERROR = 'DELETE_PALLET_ERROR';
