export const isExpired = token => {
  try {
    if (!token) {
      return true;
    }

    const [, payload] = token.split('.');
    const decoded = JSON.parse(atob(payload));
    if (!decoded?.exp) {
      return true;
    }

    const expiration = decoded.exp * 1000;
    return Date.now() > expiration;
  } catch (error) {
    return true;
  }
}

export const hasPermission = (user, scope) => {
    const levels = scope.split('/');
    const last = levels[levels.length - 1];
    const { permissions } = user;
    let nested = '';
    let authorized = false;

    levels.forEach(level => {
        // we have to go deeper
        nested += `${level}/`;
        const found = permissions.find(
            permission => permission === `${nested}*` || permission === `${nested}${last}`
        );
        if (found) {
            authorized = true;
        }
    });

    if (authorized) {
        return authorized;
    }

    authorized = permissions.some(permission => permission.search(scope) > -1);

    return authorized;
};