import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { blue, red, green, orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles.css';

const cellStyles = (theme) => ({
	primaryColor: {
		backgroundColor: theme.palette.li.primary,
	},
	secondaryColor: {
		backgroundColor: theme.palette.li.secondary,
	},
	red: {
		color: red[800],
	},
	blue: {
		color: blue[800],
	},
	green: {
		color: green[800],
	},
	yellow: {
		color: orange[900],
	},
});

const ProductComponent = ({ index, divider, classes, name, imageUrl, quantity, color, key, batch }) => {
	const CellText = ({ children }) => <h1 className={styles.productText}>{children}</h1>;

	CellText.propTypes = {
		children: PropTypes.any.isRequired,
	};

	return (
		<ListItem
			className={`${index % 2 === 0 ? classes.primaryColor : classes.secondaryColor}`}
			disableGutters
			divider={divider}
			key={key}
		>
			<img src={imageUrl || ''} alt="" className={styles.productIcon} />
			<Grid container wrap="nowrap" alignItems="center" justify="space-between" spacing={24}>
				<Grid item xs={1}>
					<FlipMove duration={300} easing="ease-out">
						<Grid container wrap="nowrap" alignItems="center" key={quantity}>
							<Chip label={<CellText>{quantity}</CellText>} tabindex="0" />
							<div className={styles.multiplierIcon}>
								<CellText>x</CellText>
							</div>
						</Grid>
					</FlipMove>
				</Grid>

				<Grid item className={`${styles.batchText}`} xs={2}>
					<CellText>
						<span  className={`${classes[color]}`}>{batch}</span>
					</CellText>
				</Grid>

				<Grid item xs={9}>
					<CellText>
						<span className={`${styles.productText} ${classes[color]}`}>{name}</span>
					</CellText>
				</Grid>
			</Grid>
		</ListItem>
	);
};

ProductComponent.propTypes = {
	withCounter: PropTypes.bool,
	index: PropTypes.number,
	divider: PropTypes.bool,
	classes: PropTypes.object.isRequired,
};

ProductComponent.defaultProps = {
	withCounter: false,
	index: 0,
	divider: false,
};

export default withStyles(cellStyles)(ProductComponent);
