export default theme => ({
	dcSelect: {
		marginTop: '70px',
	},
	dcInput: {
		width: '280px',
		margin: 'auto',
	},
	orderInputPaper: {
		padding: 2 * theme.spacing.unit,
	},
	title: {
		marginBottom: 2 * theme.spacing.unit,
	},
	orderInputError: {
		color: theme.palette.secondary.main,
	},
	button: {
		marginTop: 3 * theme.spacing.unit,
	},
	barcodeInput: {
		width: '280px',
		margin: 'auto',
	},
	barcodeInputItemContainer: {
		position: 'relative',
	},
	circularProgress: {
		position: 'absolute',
		top: '31%',
		left: '42%',
	},
	barcodeInputContainer: {
		padding: 2 * theme.spacing.unit,
		height: '170px',
	},
	addIconButton: {
		marginTop: '-16px',
	},
	infoGridContainer: {
		width: '100%',
		margin: 0,
	},
	infoItemMobile: {},
	infoPaper: {
		height: '100%',
		overflow: 'hidden',
		padding: '5px'
	},
	infoGridHeader: {
		opacity: 0.9,
		padding: theme.spacing.unit,
		paddingLeft: 2 * theme.spacing.unit,
	},
	infoGridHeaderMobile: {
		opacity: 0.9,
		padding: 0.5 * theme.spacing.unit,
		paddingLeft: 2 * theme.spacing.unit,
		fontSize: '16px',
	},
	infoGridItem: {
		opacity: 0.9,
		padding: theme.spacing.unit,
		paddingLeft: 2 * theme.spacing.unit,
		fontWeight: 400,
	},
	infoGridItemMobile: {
		opacity: 0.8,
		padding: 0.5 * theme.spacing.unit,
		paddingLeft: 2 * theme.spacing.unit,
		fontWeight: 400,
		fontSize: '16px',
	},
	productsTablePaperContainer: {
		width: '100%',
		marginTop: `${window.innerWidth < 800 ? '8px' : '32px'}`,
		overflow: 'hidden',
	},
	productTableFlipMove: {
		width: '100%',
	},
	productText: {
		margin: 0,
	},
	productItemContainer: {
		...(window.innerWidth < 800 && { height: '78px' }),
		paddingLeft: '8px',
		paddingRight: '8px',
	},
	productGridContainer: {
		height: '100%',
	},
	productIconContainer: {
		width: '96px',
		height: '72px',
	},
	productNameContainer: {
		height: '100%',
		overflowY: 'auto',
	},
	productIcon: {
		height: '64px',
		width: '64px',
		marginRight: '16px',
		marginLeft: '16px',
	},
	bulletText: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	pending: {
		backgroundColor: '#ffc110',
	},
	primaryColor: {
		backgroundColor: theme.palette.checkLi.primary,
		color: theme.palette.common.white,
	},
	secondaryColor: {
		backgroundColor: theme.palette.checkLi.secondary,
		color: theme.palette.common.white,
	},
	addItemModal: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
	},
	addItemModalDesktop: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '30%',
	},
	modalTitle: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	addItemModalContainer: {
		width: '100%',
		padding: theme.spacing.unit,
	},
	confirmModalFields: {
		width: '100%',
		margin: '10px 0',
	},
	inputContainer: {
		position: 'relative',
	},
	input: {
		width: '100%',
		border: `1px solid #cccccc`,
		borderRadius: '4px',
		paddingLeft: '8px',
		height: `38px`,
		'&:hover': {
			borderColor: '#b3b3b3',
		},
	},
	placeHolderAndLabel: {
		position: 'absolute',
		fontFamily: `Roboto, sans-serif`,
		pointerEvents: 'none',
		transition: 'font-size 0.1s, top 0.1s',
		color: '#808080',
	},
	placeholder: {
		fontSize: '14px',
		left: '10px',
		top: '9px',
		opacity: 0.9,
	},
	label: {
		left: '6px',
		paddingLeft: '4px',
		paddingRight: '4px',
		backgroundColor: theme.palette.common.white,
		opacity: 1,
		top: '-9px',
		fontSize: '11px',
	},
	buttonsContainer: {
		marginTop: theme.spacing.unit,
	},
	finishCheckButton: {
		marginTop: theme.spacing.unit,
		height: '78px',
	},
	noOrdersCardPaper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px',
	},
	noOrdersCardGrid: {
		height: '100%',
		alignItems: "center",
	},
	noOrdersCardText: {
		opacity: 0.9,
		padding: theme.spacing.unit,
		paddingLeft: 2 * theme.spacing.unit,
		fontWeight: 400,
	},
	noOrdersCardButtonsGrid: {
		wrap: 'nowrap',
	},
	noOrdersCardButton: {
		margin: '10px',
	},
	deleteIcon: {
		color: theme.palette.secondary.main,
	},
	deleteContainer: {
		padding: "2px"
	}
});
