import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import moment from "moment";
import { PropTypes } from 'prop-types';
import React from 'react';
import FlipMove from 'react-flip-move';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { compose } from 'redux';

import { parseBagBarcode } from 'beltops/barcode/bag';

import { errorNotification, sendNotification } from 'actions/Notifications';
import { errorClear, getProductsInfos } from 'actions/ProductsInfo';
import {
	confirmReceivedPackage,
	fetchDcReceivedOrders,
	undoOrderConfirmation,
	finishTransferCheck,
	notifyWorkSaved,
} from 'actions/TransferCheck';
import AlertDialog from 'components/AlertDialog';
import BarCodeInput, { checkUsingRegex } from 'components/BarCodeInput';
import ScannerInput from 'components/ScannerInput';
import { PACKAGE_BARCODE_REGEX } from 'constants/Regexes';
import DCSelect from 'containers/DCSelect';
import ProductComponent from '../components/ProductComponent';
import { RejectedBatchModal } from '../components/RejectedBatchModal';
import { ALREADY_READ_ERROR, PRODUCT_NOT_FOUND, WRONG_PACKAGE } from '../constants/errors';
import { LETTERS } from '../constants/values';
import styles from '../styles';

const expirationModeCut = moment(process.env.EXPIRATION_MODE_CUT).toDate();

const getSkuFromPackageBarcode = barcode => {
	if (barcode.length === 20) {
		return barcode.slice(0, 4).toUpperCase();
	}
	if (barcode.length === 22) {
		return barcode.slice(2, 6).toUpperCase();
	}
};

const getQuantityFromPackageBarcode = barcode => {
	if (barcode.length === 20) {
		return Number(barcode.slice(4, 7));
	}
	if (barcode.length === 22) {
		return Number(barcode.slice(6, 9));
	}
};

const getManufacturingDateFromPackageBarcode = barcode =>
	moment(barcode.substr(9, 8), 'YYYYMMDD');

class TransferCheckContainer extends React.Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		const { step, pendingConfirmationPackage } = prevState;
		const { receivedOrders, done } = nextProps;
		console.log({ receivedOrders, done });
		if (step !== 0 && done) {
			return {
				step: 0,
				receivedOrders: null,
				busyScanner: false,
				pendingConfirmationPackage: null,
				addItemModal: false,
				selected: '',
				quantity: '',
				focusedQuantityInput: false,
				confirmationDialogOpen: false,
				undoOrderConfirmationDialogOpen: false,
				selectedOrderNumber: null,
			};
		}
		if (receivedOrders) {
			return {
				...prevState,
				step: 1,
				receivedOrders,
				...(pendingConfirmationPackage &&
					receivedOrders.receivedBarcodes.includes(pendingConfirmationPackage.barcode) && {
					pendingConfirmationPackage: null,
				}),
			};
		}
		return {
			...prevState,
			receivedOrders,
		};
	}

	static renderLoading() {
		return (
			<Grid
				container
				alignItems="center"
				justify="center"
				className={styles.loadingContainer}
			>
				<CircularProgress size={50} />
			</Grid>
		);
	}

	constructor(props) {
		super(props);

		this.state = {
			step: 0,
			receivedOrders: null,
			busyScanner: false,
			pendingConfirmationPackage: null,
			addItemModal: false,
			selected: '',
			quantity: '',
			focusedQuantityInput: false,
			focusedProductInput: false,
			productName: '',
			batch: '',
			confirmationDialogOpen: false,
			undoOrderConfirmationDialogOpen: false,
			selectedOrderNumber: null,
		};
	}

	componentDidMount() {
		this.props.getProductsInfos({ rejectedBatches: true });
	}

	addPackageProductsToList(barcode) {
		const sku = getSkuFromPackageBarcode(barcode).toUpperCase();
		const quantity = getQuantityFromPackageBarcode(barcode);
		const [, product] = this.getProduct(this.props.products, { sku, batch: barcode });
		if (product) {
			this.setState(prevState => {
				const { receivedOrders, pendingConfirmationPackage } = prevState;
				if (pendingConfirmationPackage && barcode !== pendingConfirmationPackage.barcode) {
					this.props.errorNotification({ message: WRONG_PACKAGE });
					return prevState;
				}

				if (pendingConfirmationPackage) {
					console.log('ADD_ITEM: ', product);
					this.props.confirmReceivedPackage({
						orders: receivedOrders.orders,
						barcode,
						sku,
						quantity: String(quantity),
						product
					});
					return {
						...prevState,
					};
				}

				if (!receivedOrders.receivedBarcodes.includes(barcode)) {
					return {
						...prevState,
						pendingConfirmationPackage: {
							barcode,
							sku,
							quantity,
						},
					};
				}
				this.props.errorNotification({ message: ALREADY_READ_ERROR });
				return prevState;
			});
		} else {
			this.props.errorNotification({ message: PRODUCT_NOT_FOUND });
		}
	}

	inverseCase = string => {
		let invertedString = '';
		for (let i = 0; i < string.length; i++) {
			if (string.charAt(i) === string.charAt(i).toLowerCase()) {
				invertedString = `${invertedString}${string.charAt(i).toUpperCase()}`;
			} else if (string.charAt(i) === string.charAt(i).toUpperCase()) {
				invertedString = `${invertedString}${string.charAt(i).toLowerCase()}`;
			}
		}
		return invertedString;
	};

	getLetter = code => LETTERS[code - 1];

	describeCode = code => ({
		sku: `${this.getLetter(Number(code.slice(1, 3)))}${code.slice(3, 6)}`,
		batch: code,
	});

	getProduct = (products, { batch, sku }) => {
		if (sku in products) {
			return [sku, products[sku]];
		}

		return (
			Object.entries(products).find(
				([, product]) => String(product.barcode) === batch
			) || ['', {}]
		);
	};

	handleProductCodeSubmission = value => {
		if (value.length < 7) return;
		const codeInfo = this.describeCode(value);
		const { products } = this.props;
		const [sku, product] = this.getProduct(products, codeInfo);

		this.setState({
			productName: product.name || '',
			selected: sku || '',
			batch: value,
		});
	};

	handleRegisterSubmission = value => {
		if (!checkUsingRegex(value, PACKAGE_BARCODE_REGEX)) {
			return;
		}
		let actualValue = value;
		if (value.slice(0, 1) === value.slice(0, 1).toLowerCase()) {
			actualValue = this.inverseCase(value);
		}
		this.addPackageProductsToList(actualValue);
	};

	handleRejectModalOkClick = () => {
		this.setState({
			rejectedBatch: false,
		});
	};

	handleCheckValid = value => {
		if (!this.state.busyScanner && checkUsingRegex(value, PACKAGE_BARCODE_REGEX)) {
			this.setState({
				busyScanner: true,
			});
			setTimeout(() => {
				this.setState({
					busyScanner: false,
				});
			}, 1000);
			return true;
		}
		return false;
	};

	handleFocus = () => {
		this.setState(prevState => {
			if (prevState.quantity === '') {
				return {
					...prevState,
					focusedQuantityInput: !prevState.focusedQuantityInput,
				};
			}
		});
	};

	toggleProductFocus = () => {
		this.setState(prevState => {
			if (prevState.quantity === '') {
				return {
					...prevState,
					focusedProductInput: !prevState.focusedProductInput,
				};
			}
		});
	};

	handleClear = () => {
		this.setState(state => ({
			...state,
			pendingConfirmationPackage: null,
		}));
	};

	handleFinish = () => {
		this.props.finishTransferCheck(this.state.receivedOrders.orders);
		this.handleCloseConfirmationDialog();
		this.resetState();
	};

	handlePause = () => {
		this.props.notifyWorkSaved();
	}

	handleModalClose = () => {
		this.props.errorClear();
	};

	handleBarCodeInputError = message => () =>
		this.props.errorNotification({
			message,
		});

	handleAddItemManually = () => {
		const { pendingConfirmationPackage } = this.state;
		if (!pendingConfirmationPackage) {
			this.setState(prevState => ({
				...prevState,
				addItemModal: true,
			}));
		}
	};

	handleUndoOrderConfirmModalClose = () => {
		this.setState(prevState => ({
			...prevState,
			undoOrderConfirmationDialogOpen: false,
			selectedOrderNumber: null,
		}))
	}

  handleUndoOrderConfirm = () => {
		this.props.undoOrderConfirmation(this.state.selectedOrderNumber);
		this.handleUndoOrderConfirmModalClose();
  }

	handleUndoReceivedOrderRequest = (orderNumber) => {
		this.setState(prevState => ({
			...prevState,
			selectedOrderNumber: orderNumber,
			undoOrderConfirmationDialogOpen: true,
		}));
	}

	handleAddItemManuallyConfirm = () => {
		console.log('ADD_MANUAL');
		this.setState(prevState => {
			const { receivedOrders, selected, quantity, batch, productName } = prevState;
			const [, product] = this.getProduct(this.props.products, { batch, sku: selected });
			if (product && quantity !== '') {
				const { expiration } = parseBagBarcode(batch, {
					expirationModeCut,
					getExpiration: () => product.expiration,
				});
				this.props.confirmReceivedPackage({
					orders: receivedOrders.orders,
					product,
					sku: selected,
					quantity,
					batch,
					productName,
				});
				return {
					...prevState,
					addItemModal: false,
					selected: '',
					quantity: '',
					batch: '',
					productName: '',
					focusedQuantityInput: false,
				};
			}
		});
	};

	handleAddItemModalClose = () => {
		this.setState(prevState => ({
			...prevState,
			addItemModal: false,
			selected: '',
			quantity: '',
			productName: '',
			batch: '',
		}));
	};

	formatPositiveRealsInput(currentValue, value) {
		if (value === '.') {
			return '0.';
		} else if (!isNaN(value)) {
			const valueArray = value.split('');
			if (!valueArray.some(char => char === 'e')) {
				if (value < 0) {
					return 0;
				} else if (
					valueArray[0] === '0' &&
					valueArray[1] &&
					valueArray[1] !== '.' &&
					valueArray[1] !== ' '
				) {
					return value.slice(1);
				} else if (value.endsWith(' ')) {
					return value.slice(0, value.length - 1);
				}
				return value;
			}
		}
		return currentValue;
	}

	handleChange = event => {
		const { value, id } = event.target;

		this.setState(prevState => ({
			...prevState,
			...(id === 'quantityInput' && {
				quantity: this.formatPositiveRealsInput(prevState.quantity, value),
			}),
		}));
	};

	handleCloseConfirmationDialog = () => {
		this.setState(prevState => ({
			...prevState,
			confirmationDialogOpen: false,
		}))
	};

	handleOpenConfirmationDialog = () => {
		this.setState(prevState => ({
			...prevState,
			confirmationDialogOpen: true,
		}))
	};

	handleSelect = selected => {
		this.setState({ selected: selected.value });
	};

	handleStart = () => {
		this.props.fetchDcReceivedOrders();
		this.setState(prevState => ({
			...prevState,
			step: 1,
		}));
	};

	resetState = () => {
		this.setState(() => ({
			step: 0,
			receivedOrders: null,
			busyScanner: false,
			pendingConfirmationPackage: null,
			addItemModal: false,
			selected: '',
			quantity: '',
			focusedQuantityInput: false,
			focusedProductInput: false,
			productName: '',
			batch: '',
			confirmationDialogOpen: false,
			undoOrderConfirmationDialogOpen: false,
			selectedOrderNumber: null,
		}))
	}

	renderUndoOrderConfirmModal() {
		const { undoOrderConfirmationDialogOpen, selectedOrderNumber } = this.state

		return (
			<AlertDialog
				open={undoOrderConfirmationDialogOpen}
				title='Desfazer recebimento de ordem'
				onClose={this.handleUndoOrderConfirmModalClose}
				actions={[
					{
						onClick: this.handleUndoOrderConfirm,
						title: 'Sim',
						type: 'primary',
						autoFocus: false,
					},
					{
						onClick: this.handleUndoOrderConfirmModalClose,
						title: 'Não',
						type: 'default',
						autoFocus: true,
					},
				]}
			>
				Deseja desfazer o recebimento da ordem <b>{selectedOrderNumber}</b>?
			</AlertDialog>
		)
	}

	renderAddItemModal() {
		const { classes } = this.props;
		const mobile = window.innerWidth < 800;
		return (
			<Modal
				title="Conferência manual"
				open={this.state.addItemModal}
				onClose={this.handleAddItemModalClose}
			>
				<Paper className={mobile ? classes.addItemModal : classes.addItemModalDesktop}>
					<Grid
						container
						direction="column"
						alignItems="center"
						className={classes.addItemModalContainer}
					>
						<Grid container justify="center">
							<Typography
								variant="h6"
								id="modal-title"
								className={classes.modalTitle}
							>
								<b>Conferência Manual</b>
							</Typography>
						</Grid>
						<Grid container justify="space-between">
							<Grid item xs={12} className={classes.confirmModalFields}>
								<ScannerInput
									onChange={this.handleProductCodeSubmission}
									render={() => (
										<div className={classes.inputContainer}>
											<input
												type="text"
												className={classNames(classes.input)}
												value=""
												autoFocus
												readOnly
											/>
											<span
												className={classNames(
													classes.placeHolderAndLabel,
													classes.placeholder
												)}
											>
												Código de Barras
											</span>
										</div>
									)}
								/>
							</Grid>
							<Grid item xs={12} className={classes.confirmModalFields}>
								<div className={classes.inputContainer}>
									<input
										type="text"
										className={classNames(classes.input)}
										value={this.state.productName}
										onFocus={this.toggleProductFocus}
										onBlur={this.toggleProductFocus}
										readOnly
									/>
									<span
										className={classNames(
											classes.placeHolderAndLabel,
											this.state.focusedProductInput || this.state.productName
												? classes.label
												: classes.placeholder
										)}
									>
										Produto
									</span>
								</div>
							</Grid>
							<Grid item xs={12} className={classes.confirmModalFields}>
								<div className={classes.inputContainer}>
									<input
										id="quantityInput"
										type="text"
										onFocus={this.handleFocus}
										onBlur={this.handleFocus}
										className={classNames(classes.input)}
										onChange={this.handleChange}
										value={this.state.quantity}
									/>
									<span
										className={classNames(
											classes.placeHolderAndLabel,
											this.state.focusedQuantityInput
												? classes.label
												: classes.placeholder
										)}
									>
										Quantidade
									</span>
								</div>
							</Grid>
						</Grid>
						<Grid
							container
							direction="row"
							justify="space-between"
							className={classes.buttonsContainer}
						>
							<Button
								variant="raised"
								color="secondary"
								onClick={this.handleAddItemModalClose}
							>
								Cancelar
							</Button>
							<Button
								variant="raised"
								color="primary"
								onClick={this.handleAddItemManuallyConfirm}
							>
								Confirmar
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Modal>
		);
	}

	renderDCSelect() {
		const { classes } = this.props;
		const mobile = window.innerWidth < 800;
		return (
			<Grid container justify="center" alignItems="center" className={classes.dcSelect}>
				<Grid item xs={mobile ? 12 : 4}>
					<Card>
						{!this.props.products ? (
							<CircularProgress size={50} />
						) : (
								<CardContent>
									<Grid container direction="column" alignItems="center">
										<Grid item>
											<h2>Escolha o centro de distribuição</h2>
										</Grid>
										<Grid item className={classes.dcInput}>
											<DCSelect />
										</Grid>
										<Grid item justify="center">
											<Button
												variant="raised"
												color="primary"
												onClick={this.handleStart}
												className={classes.start}
											>
												Ok
										</Button>
										</Grid>
									</Grid>
								</CardContent>
							)}
					</Card>
				</Grid>
			</Grid>
		);
	}

	renderOrderList(orders, itemClassName) {
		const { classes } = this.props;

		return orders?.map(order => 
			<Button
				id={`order-${order}`}
				className={classes.deleteContainer}
				onClick={() => this.handleUndoReceivedOrderRequest(order)}
				mini
			>
				<Typography variant="h6" className={itemClassName}> {order} </Typography>
				<DeleteIcon className={classes.deleteIcon} />
			</Button>
		)
	}

	renderCard() {
		const { receivedOrders } = this.state;
		console.log('RENDER_CARD: ', receivedOrders);
		const { classes, products, waitingConfirmation } = this.props;
		const { addItemModal } = this.state;
		const mobile = window.innerWidth < 800;
		return mobile ? (
			<Grid container spacing={8} direction="column">
				<Grid item xs className={classes.infoItemMobile}>
					<Paper elevation={1} className={classes.infoPaper}>
						<Grid
							container
							direction="column"
							className={classes.infoGridContainer}
							justify="center"
						>
							<Grid container direction="row" alignItems="center" justify="flex-start">
								<Grid item>
									<Typography variant="h6" className={classes.infoGridHeaderMobile}>
										Ordens:
									</Typography>
								</Grid>
								{
									this.renderOrderList(this.state.receivedOrders?.orders, classes.infoGridHeaderMobile).map(el => 
										<Grid item>{el}</Grid>	
									)
								}
							</Grid>
							<Grid container direction="row">
								<Grid item>
									<Typography variant="h6" className={classes.infoGridItemMobile}>
										Total lido: {this.state.receivedOrders && this.state.receivedOrders.totalQuantityReceived}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="h6" className={classes.infoGridItemMobile}>
										Total esperado: {this.state.receivedOrders && this.state.receivedOrders.totalQuantitySent}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs className={classes.barcodeInputItemContainer}>
					<Paper elevation={1} className={classes.barcodeInputContainer}>
						{!products || waitingConfirmation || addItemModal ? (
							<CircularProgress size={50} className={classes.circularProgress} />
						) : (
								<Grid container direction="column">
									<Grid container justify="space-between">
										<Grid item>Leia produtos</Grid>
										<Grid item>
											<IconButton
												aria-label="Add"
												className={classes.addIconButton}
												onClick={this.handleAddItemManually}
											>
												<AddIcon />
											</IconButton>
										</Grid>
									</Grid>
									<Grid item className={classes.barcodeInput}>
										<BarCodeInput
											onMatch={this.handleRegisterSubmission}
											checkValid={this.handleCheckValid}
											onError={this.handleBarCodeInputError(
												'Erro na leitura. Espere alguns instantes entre as leituras.'
											)}
											keepFocus
											debounce
											disableKeyboard
										/>
									</Grid>
									<Grid item>
										<Grid container justify="space-between">
											<Button
												variant="raised"
												color="secondary"
												onClick={this.handleClear}
											>
												Limpar
										</Button>
											<Button
												variant="raised"
												color="primary"
												onClick={this.handlePause}
											>
												Pausar
										</Button>
										</Grid>
									</Grid>
								</Grid>
							)}
					</Paper>
				</Grid>
			</Grid>
		) : (
				<Grid container spacing={24} direction="row">
					<Grid item xs className={classes.barcodeInputItemContainer}>
						{!products || waitingConfirmation || addItemModal ? (
							<CircularProgress size={50} className={classes.circularProgress} />
						) : (
								<Paper elevation={1} className={classes.barcodeInputContainer}>
									<Grid container direction="column">
										<Grid container justify="space-between">
											<Grid item>Leia produtos</Grid>
											<Grid item>
												<IconButton
													aria-label="Add"
													className={classes.addIconButton}
													onClick={this.handleAddItemManually}
												>
													<AddIcon />
												</IconButton>
											</Grid>
										</Grid>

										<Grid item className={classes.barcodeInput}>
											<BarCodeInput
												onMatch={this.handleRegisterSubmission}
												checkValid={this.handleCheckValid}
												onError={this.handleBarCodeInputError}
												keepFocus
												debounce
											/>
										</Grid>
										<Grid item>
											<Grid container justify="space-between">
												<Button
													variant="raised"
													color="secondary"
													onClick={this.handleClear}
												>
													Limpar
												</Button>
												<Button
													variant="raised"
													color="primary"
													onClick={this.handlePause}
												>
													Pausar
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							)}
					</Grid>
					<Grid item xs>
						<Paper elevation={1} className={classes.infoPaper}>
							<Grid container direction="row" alignItems="flex-start" justify="flex-start" style={{ minBlockSize: '4.5vi' }}>
								<Grid item xl={2} md={3} sm={6}>
									<Typography variant="h6" className={classes.infoGridHeader}>Ordens:</Typography>
								</Grid>
								{
									this.renderOrderList(this.state.receivedOrders?.orders, classes.infoGridHeader).map(el => 
										<Grid item xl={2} md={3} sm={6}>{el}</Grid>	
									)
								}
							</Grid>
							<Grid
								container
								direction="row"
								className={classes.infoGridContainer}
								justify="flex-start"
								alignItems="center"
							>
								<Grid item xl={4} md={6} sm={12}>
									<Typography variant="h6" className={classes.infoGridItem}>
										Total esperado: {this.state.receivedOrders && this.state.receivedOrders.totalQuantitySent}
									</Typography>
								</Grid>
								<Grid item xl={4} md={6} sm={12}>
									<Typography variant="h6" className={classes.infoGridItem}>
										Total lido: {this.state.receivedOrders && this.state.receivedOrders.totalQuantityReceived}
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			);
	}

	renderProductsTable() {
		const { pendingConfirmationPackage, receivedOrders } = this.state;
		console.log('RENDER_PRODUCTS_TABLE: ', receivedOrders);
		const { products, classes } = this.props;
		return (
			<Paper elevation={1} className={classes.productsTablePaperContainer}>
				{receivedOrders && (
					<Grid container xs={12} direction="column">
						<FlipMove
							duration={300}
							easing="ease-out"
							className={classes.productTableFlipMove}
						>
							{pendingConfirmationPackage && (
								<ProductComponent
									index={0}
									sku={pendingConfirmationPackage.sku}
									name={products[pendingConfirmationPackage.sku].name}
									imageUrl={products[pendingConfirmationPackage.sku].imageUrl}
									quantity={pendingConfirmationPackage.quantity}
									color={products[pendingConfirmationPackage.sku].color}
								/>
							)}
							{receivedOrders.transferInItems &&
								receivedOrders.transferInItems.map((item, index) => {
									const { sku, quantity, batch } = item;
									const [, { name, imageUrl, color }] = this.getProduct(products, { sku, batch });
									return (
										<ProductComponent
											index={index}
											sku={sku}
											name={name}
											imageUrl={imageUrl}
											quantity={quantity}
											color={color}
											confirmed
										/>
									);
								})}
						</FlipMove>
						<Button
							variant="raised"
							color="primary"
							className={classes.finishCheckButton}
							onClick={this.handleOpenConfirmationDialog}
						>
							Finalizar Conferência
						</Button>
					</Grid>
				)}
			</Paper>
		);
	}

	renderNoOrdersCard() {
		const { classes, fetchDcReceivedOrders } = this.props;
		const mobile = window.innerWidth < 800;
		console.log({ mobile });
		return (
			<Paper elevation={1} className={classes.noOrdersCardPaper}>
				<Grid container direction="row" xs={12} className={classes.noOrdersCardGrid}>
					<Grid item lg={8} sm={12} justify="flex-start">
						<Typography variant="h6" className={classes.noOrdersCardText}>
							Não existem ordens a serem conferidas neste CD.
						</Typography>
					</Grid>
					<Grid container item lg={4} sm={12} direction="row" spacing={4} className={classes.noOrdersCardButtonsGrid}>
						<Grid item>
							<Button
								variant="raised"
								color="secondary"
								onClick={this.resetState}
								className={classes.noOrdersCardButton}
							>
								Voltar
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="raised"
								color="primary"
								onClick={fetchDcReceivedOrders}
								className={classes.noOrdersCardButton}
							>
								Buscar novamente
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	}

	renderErrorModal() {
		const { error } = this.props;
		return (
			<AlertDialog
				open={error}
				title={`Erro: ${error}`}
				onClose={this.handleModalClose}
				actions={[
					{
						onClick: this.handleModalClose,
						title: 'Ok',
						type: 'primary',
						autoFocus: true,
					},
				]}
			>
				Clique em 'OK' e tente novamente
			</AlertDialog>
		);
	}

	renderConfirmationDialog() {
		const { receivedOrders, confirmationDialogOpen } = this.state;
		return (
			<AlertDialog
				open={confirmationDialogOpen}
				title={'Tem certeza de que deseja finalizar a conferência das seguintes ordens?'}
				onClose={this.handleCloseConfirmationDialog}
				actions={[
					{
						onClick: this.handleCloseConfirmationDialog,
						title: 'Não',
						type: 'secondary',
					},
					{
						onClick: this.handleFinish,
						title: 'Sim',
						type: 'primary',
					},
				]}
			>
				{receivedOrders.orders.join(', ')}
			</AlertDialog>
		)
	}

	renderRejectedBatchModal() {
		return (
			<Modal open={this.state.rejectedBatch}>
				<RejectedBatchModal onClick={this.handleRejectModalOkClick} />
			</Modal>
		);
	}

	render() {
		const { loading, products, waitingFinish } = this.props;
		const { step, receivedOrders } = this.state;
		const isLoading = (loading || waitingFinish || !products);
		return (
			<Grid direction="column">
				{isLoading && TransferCheckContainer.renderLoading()}
				{!isLoading && step === 0 && this.renderDCSelect()}
				{!isLoading && receivedOrders && step === 1 && this.renderCard()}
				{!isLoading && receivedOrders && step === 1 && this.renderProductsTable()}
				{!isLoading && receivedOrders && step === 1 && this.renderConfirmationDialog()}
				{!isLoading && !receivedOrders && step !== 0 && this.renderNoOrdersCard()}
				{this.renderUndoOrderConfirmModal()}
				{this.renderErrorModal()}
				{this.renderAddItemModal()}
				{this.renderRejectedBatchModal()}
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.transferCheck.loading,
	error: state.transferCheck.error,
	success: state.transferCheck.success,
	products: state.productsLoss.products,
	rejectedBatches: state.productsLoss.rejectedBatches,
	receivedOrders: state.transferCheck.receivedOrders,
	waitingConfirmation: state.transferCheck.waitingConfirmation,
	waitingFinish: state.transferCheck.waitingFinish,
	done: state.transferCheck.done,
});

TransferCheckContainer.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.bool,
	products: PropTypes.array,
	done: PropTypes.bool,
	sendNotification: PropTypes.func.isRequired,
	errorNotification: PropTypes.func.isRequired,
	errorClear: PropTypes.func.isRequired,
	getProductsInfos: PropTypes.func.isRequired,
	fetchDcReceivedOrders: PropTypes.func.isRequired,
	undoOrderConfirmation: PropTypes.func.isRequired,
};

TransferCheckContainer.defaultProps = {
	loading: false,
	error: false,
	products: [],
	done: false,
};

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		{
			getProductsInfos,
			sendNotification,
			errorNotification,
			errorClear,
			fetchDcReceivedOrders,
			undoOrderConfirmation,
			confirmReceivedPackage,
			finishTransferCheck,
			notifyWorkSaved,
		}
	)
)(TransferCheckContainer);
