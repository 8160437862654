import axios from 'axios/index';

import { handleRequestError } from '../actions/ErrorHandling';
import { sendNotification } from '../actions/Notifications';
import { inventoryApi } from '../api/inventoryApi';
import {
	TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_ERROR,
	TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_REQUEST,
	TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_RESPONSE,
	TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_ERROR,
	TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_REQUEST,
	TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_RESPONSE,
	TRANSFER_CHECK_FETCH_DC_ORDERS_ERROR,
	TRANSFER_CHECK_FETCH_DC_ORDERS_REQUEST,
	TRANSFER_CHECK_FETCH_DC_ORDERS_RESPONSE,
	TRANSFER_CHECK_FETCH_INVENTORY_ORDER_ERROR,
	TRANSFER_CHECK_FETCH_INVENTORY_ORDER_REQUEST,
	TRANSFER_CHECK_FETCH_INVENTORY_ORDER_RESPONSE,
	TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_ERROR,
	TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_REQUEST,
	TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_RESPONSE,
	TRANSFER_CHECK_FINISH_ERROR,
	TRANSFER_CHECK_FINISH_REQUEST,
	TRANSFER_CHECK_FINISH_RESPONSE,
	TRANSFER_CHECK_STORE_RESET,
	TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_ERROR,
	TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_REQUEST,
	TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_RESPONSE,
} from '../constants/ActionTypes';
import { INVENTORY_API_ADDRESS } from '../constants/InventoryAPI';
import { getAxiosErrorResponseMessage } from '../utils/getAxiosErrorResponseMessage';

export const confirmDcOrderArrival = order => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_REQUEST,
		});
		const response = await inventoryApi.put(`/orders/receive/${order}`);
		dispatch({
			type: TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_RESPONSE,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: TRANSFER_CHECK_CONFIRM_ARRIVAL_DC_ORDERS_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const undoOrderConfirmation = orderNumber => async (dispatch, getState) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_REQUEST,
		});
		await inventoryApi.put(`/orders/undo-receive/${orderNumber}`);
		dispatch({
			type: TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_RESPONSE,
			payload: { number: orderNumber },
		});
	} catch (err) {
		dispatch({
			type: TRANSFER_CHECK_UNDO_ORDER_CONFIRMATION_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
	}
}

export const fetchDcReceivedOrders = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_REQUEST,
		});
		const distributionCenter = getState().distributionCenters.selected;
		const response = await inventoryApi.get(`/orders/received/${distributionCenter}`)
		dispatch({
			type: TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_RESPONSE,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: TRANSFER_CHECK_FETCH_RECEIVED_ORDERS_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
		dispatch(handleRequestError(err));
	}
}

export const fetchDcOrders = () => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_FETCH_DC_ORDERS_REQUEST,
		});
		const distributionCenter = getState().distributionCenters.selected;
		const response = await inventoryApi.get(`/orders/${distributionCenter}`);

		dispatch({
			type: TRANSFER_CHECK_FETCH_DC_ORDERS_RESPONSE,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: TRANSFER_CHECK_FETCH_DC_ORDERS_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const fetchInventoryOrder = order => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_FETCH_INVENTORY_ORDER_REQUEST,
		});
		const distributionCenter = getState().distributionCenters.selected;
		const authToken = localStorage.getItem('authToken');
		const response = await axios.get(`${INVENTORY_API_ADDRESS}/orders/get`, {
			headers: {
				Authorization: authToken ? `Bearer ${authToken}` : '',
				'Content-Type': 'application/json',
			},
			params: {
				query: {
					number: order,
				},
			},
		});
		if (response.data.destination.location !== distributionCenter) {
			throw new Error('Essa ordem não é para o centro de distribuição selecionado');
		} else {
			dispatch({
				type: TRANSFER_CHECK_FETCH_INVENTORY_ORDER_RESPONSE,
				payload: response.data,
			});
		}
	} catch (err) {
		const errorMessage = err.response ? err.response.data.message : err.message;
		dispatch({
			type: TRANSFER_CHECK_FETCH_INVENTORY_ORDER_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const confirmReceivedPackage = ({
	orders,
	barcode,
	product,
	sku,
	quantity,
	batch
}) => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_REQUEST,
			payload: {
				sku,
				quantity: Number(quantity),
				batch,
				barcode,
			},
		});
		const response = await inventoryApi.post(
			`/orders/confirm-received-package`,
			{
				orders: orders.map(String),
				barcode,
				product,
				sku,
				quantity,
				batch
			}
		);
		dispatch({
			type: TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_RESPONSE,
			payload: response.data,
		});
		dispatch(
			sendNotification({
				type: 'success',
			})
		);
	} catch (err) {
		dispatch({
			type: TRANSFER_CHECK_CONFIRM_RECEIVED_PACKAGE_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const finishTransferCheck = orders => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: TRANSFER_CHECK_FINISH_REQUEST,
		});
		const distributionCenter = getState().distributionCenters.selected;
		const response = await inventoryApi.put(
      `/orders/received/${distributionCenter}/finish`,
      {
        orders: orders.map(String),
      }
    );
		dispatch(
			sendNotification({
				type: 'success',
				message: 'Conferência de Ordem finalizada com sucesso!'
			})
		);
		dispatch({
			type: TRANSFER_CHECK_FINISH_RESPONSE,
			payload: response.data,
		});

		dispatch({
			type: TRANSFER_CHECK_STORE_RESET,
		});
	} catch (err) {
		dispatch({
			type: TRANSFER_CHECK_FINISH_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
		dispatch(handleRequestError(err));
	}
};

export const notifyWorkSaved = () => async (dispatch, _getState, _api) => {
	dispatch(sendNotification({
		type: 'success',
		message: 'Tudo salvo por aqui! Pode sair dessa tela e voltar mais tarde para finalizar a conferência.',
		autoDismiss: 0,
		soundOff: true,
	}));
};
