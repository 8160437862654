import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import BarCodeInput, { checkPackCode } from 'components/BarCodeInput';
import { DISPATCH_SEARCH_TYPES, MANUAL_CHECKING_OPTIONS } from 'constants/Dispatch';
import { styles } from '../constants/styles';
import { fixOrder } from '../logic';
import ManualCheckModal from './ManualCheckModal';
import RoutePosition from './RoutePosition';

export const PickingTypesPT = {
  frozen: 'Congelados',
  snacks: 'Secos',
  basket: 'Cestas',
  greenGrocer: 'Refrigerados',
};

class RouteDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        isOpen: false,
        orderNumber: 0,
        packingKind: '',
        pack: {
          code: '',
          location: '',
          checked: false,
        },
      },
      confirmationDialog: {
        isOpen: false,
        order: '',
        totalPacks: '',
        packingKind: '',
      },
      routeData: {
        ...props.routeData,
        orders: props.routeData.orders.map(order =>
          fixOrder(order, props.pickingTypes)
        ),
      },
    };
  }

  componentWillUnmount() {
    const { closePrintEventsStream } = this.props;
    if (closePrintEventsStream) {
      closePrintEventsStream();
    }
  }

  handleModalOpen = ({ orderNumber, packingKind, pack }) => {
    const order = this.state.routeData.orders.find(
      o => o.order === orderNumber
    );
    const pk = order.packing[packingKind].packages.find(
      p => p._id === pack._id
    );
    if (!pk.checked) {
      this.setState(state => ({
        ...state,
        modal: {
          isOpen: true,
          orderNumber,
          packingKind,
          pack: pk,
        },
      }));
    }
  };

  handleModalClose = () => {
    this.setState(state => ({
      ...state,
      modal: {
        ...state.modal,
        isOpen: false,
      },
    }));
  };

  handleModalSend = reason => {
    this.handleCheck({ reason });
  };

  handleBarcode = barcode => {
    this.handleCheck({ barcode });
  };

  handleCheck = ({ ...params }) => {
    const { reason, barcode } = params;

    const { orders, type } = this.state.routeData;
    const { orderNumber, packingKind, pack, isOpen } = this.state.modal;
    let found = false;
    let newOrders;
    let doneOrders = 0;
    let orderFound;

    if (isOpen) {
      newOrders = orders.map(order =>
        order.order === Number(orderNumber)
          ? {
              ...order,
              packing: {
                ...order.packing,
                [packingKind]: {
                  packages: order.packing[packingKind].packages.map(p =>
                    p._id === pack._id ? { ...p, checked: true } : { ...p }
                  ),
                },
              },
              totalPacks: {
                ...order.totalPacks,
                [packingKind]: {
                  ...order.totalPacks[packingKind],
                  checks: order.totalPacks[packingKind].checks + 1,
                },
              },
            }
          : { ...order }
      );
    } else {
      newOrders = orders.map(order => {
        let checkedPackingKind;
        return {
          ...order,
          packing: this.props.pickingTypes.reduce(
            (acc, pickingType) => ({
              ...acc,
              [pickingType]: {
                packages: order.packing[pickingType].packages.map(p => {
                  if (p.code === barcode && !found && !p.checked) {
                    found = true;
                    checkedPackingKind = pickingType;
                    orderFound = order.order;
                    return { ...p, checked: true };
                  }
                  return p;
                }),
              },
            }),
            {}
          ),
          totalPacks: this.props.pickingTypes.reduce(
            (acc, pickingType) => ({
              ...acc,
              [pickingType]: {
                ...order.totalPacks[pickingType],
                checks:
                  checkedPackingKind === pickingType
                    ? order.totalPacks[pickingType].checks + 1
                    : order.totalPacks[pickingType].checks,
              },
            }),
            {}
          ),
        };
      });
    }
    if (isOpen || found) {
      newOrders = newOrders.map(o =>
        o.order === (orderFound || orderNumber)
          ? {
              ...o,
              done: this.props.pickingTypes.every(
                pickingType =>
                  o.totalPacks[pickingType].number ===
                  o.totalPacks[pickingType].checks
              ),
            }
          : { ...o }
      );
      this.props.sendNotification({
        type: 'success',
        message: 'Sacola lida!',
      });
    } else {
      this.props.sendNotification({
        type: 'error',
        message: 'Sacola lida não consta na lista!',
      });
    }
    newOrders.forEach(order => {
      if (order.done) {
        doneOrders += 1;
      }
    });
    this.setState(prevState => ({
      ...prevState,
      modal: {
        ...prevState.modal,
        isOpen: false,
      },
      routeData: {
        ...prevState.routeData,
        orders: newOrders,
        done: doneOrders === orders.length,
      },
    }));
  };

  handleOpenConfirmationDialog = (order, totalPacks, packingKind) => {
    this.setState(prevState => ({
      ...prevState,
      confirmationDialog: {
        isOpen: true,
        order,
        totalPacks,
        packingKind,
      },
    }));
  };

  handleConfirmationDialogSend = () => {
    const { order, totalPacks, packingKind } = this.state.confirmationDialog;
    const { orders, type } = this.state.routeData;
    let doneOrders = 0;

    const checkedOrders = orders.map(o =>
      o.order === order
        ? {
            ...o,
            totalPacks: {
              ...o.totalPacks,
              [packingKind]: {
                ...o.totalPacks[packingKind],
                checks: totalPacks,
              },
            },
          }
        : { ...o }
    );

    const newOrders = checkedOrders.map(o => {
      const orderTypes = Object.keys(o.packing).filter(
        pickingType => o.packing[pickingType].packages.length
      );

      return o.order === order
        ? {
            ...o,
            done: orderTypes.every(
              pickingType =>
                o.totalPacks[pickingType].number ===
                o.totalPacks[pickingType].checks
            ),
          }
        : { ...o };
    });

    newOrders.forEach(o => {
      if (o.done) {
        doneOrders += 1;
      }
    });
    this.setState(prevState => ({
      ...prevState,
      confirmationDialog: {
        ...prevState.confirmationDialog,
        isOpen: false,
      },
      routeData: {
        ...prevState.routeData,
        orders: newOrders,
        done: doneOrders === orders.length,
      },
    }));
  };

  handleConfirmationDialogClose = () => {
    this.setState(prevState => ({
      ...prevState,
      confirmationDialog: {
        ...prevState.confirmationDialog,
        isOpen: false,
      },
    }));
  };

  handleCancelDispatch = () => {
    this.props.onCancel();
  };

  shouldPaintRed() {
    return (
      this.state.routeData.orders[0] &&
      this.state.routeData.orders[0].payment &&
      this.state.routeData.orders[0].payment.status &&
      this.state.routeData.orders[0].payment.status !== 'Pago'
    );
  }

  renderOrdersTableHeader() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid container alignItems="center" justify="center" direction="row">
            <Grid item xs={4} className={classes.routeDetailsHeader}>
              Local
            </Grid>
            <Grid item xs={8} style={{ width: '100%' }}>
              <Grid container justify="space-around" direction="row">
                <Grid item xs={4} className={classes.routeDetailsHeader}>
                  Nº do Pedido
                </Grid>
                <Grid item xs={4} className={classes.routeDetailsHeader}>
                  Código da Sacola
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderOrders() {
    const { pickingTypes, classes } = this.props;
    const { routeData } = this.state;
    const { orders } = routeData;

    const ordersGroupedByLocal = orders.reduce(
      (groupedOrdersAcc, currentOrder) => {
        const isPackingAvailable =
          currentOrder.packing && Object.keys(currentOrder.packing).length > 0;

        if (!isPackingAvailable) {
          return groupedOrdersAcc;
        }

        const allPackagesFromOrder = Object.values(currentOrder.packing).reduce(
          (packAcc, curPack) => [...packAcc, ...curPack.packages],
          []
        );

        const local =
          allPackagesFromOrder.find(p => p.local && p.local > 0)?.local ||
          'Local não identificado';

        groupedOrdersAcc[local] = groupedOrdersAcc[local]
          ? [...groupedOrdersAcc[local], currentOrder]
          : [currentOrder];

        return groupedOrdersAcc;
      },
      {}
    );

    return Object.keys(ordersGroupedByLocal)
      .sort()
      .map(local => (
        <Grid key={local} container className={classes.borderSeparator}>
          <RoutePosition
            local={local}
            orders={ordersGroupedByLocal[local]}
            onClick={this.handleModalOpen}
            onOpenConfirmationDialog={this.handleOpenConfirmationDialog}
            pickingTypes={pickingTypes}
          />
        </Grid>
      ));
  }

  renderManualCheckModal() {
    return (
      <Grid item xs={12}>
        <ManualCheckModal
          open={this.state.modal.isOpen}
          onRequestClose={this.handleModalClose}
          onRequestSend={this.handleModalSend}
          header="Conferência Manual"
          subHeader={this.state.modal.pack.code}
          options={MANUAL_CHECKING_OPTIONS}
          optionsHeader="Informe um motivo para a conferência manual:"
        />
      </Grid>
    );
  }

  renderBarCodeInput() {
    return (
      <Grid item xs={3} className={this.props.classes.barCodeInput}>
        <BarCodeInput
          checkValid={checkPackCode}
          onMatch={this.handleBarcode}
          debounce
          keepFocus
          toUpperCase
          // disableKeyboard
        />
      </Grid>
    );
  }

  renderOrderBillingInfo() {
    const { type, shouldRenderBillingInfo } = this.props;
    if (type === DISPATCH_SEARCH_TYPES.ORDER && shouldRenderBillingInfo) {
      const { classes } = this.props;
      return (
        <Grid item xs={12}>
          <Paper elevation={2}>
            <Grid
              container
              direction="row"
              spacing={16}
              className={classes.orderStatus}
              justify="center"
            >
              <Grid item xs={4}>
                Valor:{' '}
                <Typography className={classes.orderStatusText}>
                  {this.state.routeData.orders[0] &&
                  this.state.routeData.orders[0].payment
                    ? this.state.routeData.orders[0].payment.value
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                Método:{' '}
                <Typography className={classes.orderStatusText}>
                  {this.state.routeData.orders[0] &&
                  this.state.routeData.orders[0].payment
                    ? this.state.routeData.orders[0].payment.method
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                Status:{' '}
                <Typography
                  className={
                    this.shouldPaintRed()
                      ? classes.alert
                      : classes.orderStatusText
                  }
                >
                  {this.state.routeData.orders[0] &&
                  this.state.routeData.orders[0].payment
                    ? this.state.routeData.orders[0].payment.status
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
    return null;
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Grid container justify="flex-start">
          <Paper
            elevation={2}
            className={classes.routeNumber}
            style={{ backgroundColor: this.props.titleBackgroundColor }}
          >
            <Grid container direction="row">
              <Grid item xs={11} className={classes.routePeriod}>
                {this.props.title}
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={this.handleCancelDispatch}
                  variant="raised"
                  color="secondary"
                  className="jr-btn text-blue-gray"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  renderConfirmationDialog() {
    const {
      isOpen,
      order,
      totalPacks,
      packingKind,
    } = this.state.confirmationDialog;
    const { classes } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleConfirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Pedido <strong>{order}</strong> contém <strong>{totalPacks}</strong>{' '}
          sacolas de{' '}
          {PickingTypesPT[packingKind] &&
            PickingTypesPT[packingKind].toLowerCase()}
          ?
        </DialogTitle>
        <DialogActions
          className={`${classes.justifyContentCenter} ${classes.borderSeparator}`}
        >
          <Button
            onClick={this.handleConfirmationDialogClose}
            variant="raised"
            color="primary"
            className="jr-btn text-blue-gray"
          >
            Cancelar
          </Button>
          <Button
            onClick={this.handleConfirmationDialogSend}
            variant="raised"
            color="primary"
            className="jr-btn text-blue-gray"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderButtons() {
    const { nfcePrintingLoading, classes, onFinish } = this.props;
    return (
      <Grid container alignItems="flex-end" direction="column">
        <Grid item xs={2} className={classes.dispatchFinishButtonContainer}>
          {nfcePrintingLoading ? (
            <CircularProgress
              size={50}
              className={classes.printAllNFCesButtonCircularProgress}
            />
          ) : (
            <Button
              onClick={onFinish}
              variant="raised"
              color="primary"
              className={`jr-btn text-blue-gray ${classes.dispatchFinishButton}`}
              disabled={!this.state.routeData.done}
            >
              Finalizar
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Grid container justify="center">
        {this.renderHeader()}
        {this.renderOrderBillingInfo()}
        {this.renderBarCodeInput()}
        {this.renderOrdersTableHeader()}
        {this.renderOrders()}
        {this.renderManualCheckModal()}
        {this.renderConfirmationDialog()}
        {this.renderButtons()}
      </Grid>
    );
  }
}

RouteDetails.propTypes = {
  routeData: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  handlePrintAllNFCes: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  shouldPrintNFe: PropTypes.func.isRequired,
  pickingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

RouteDetails.defaultProps = {};

export default withStyles(styles)(RouteDetails);
