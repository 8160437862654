export function getAxiosErrorResponseMessage(err) {
  if (!err.response) {
    return err.message
  }

  if (err.response.status === 401) {
    return 'Não autorizado. Faça login novamente.'
  }

  if (typeof err.response.data?.message === 'string') {
    return err.response.data.message
  }

  if (typeof err.response.data === 'string') {
    return err.response.data
  }

  return err.message
}
