import React from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'utils/IntlMessages';
import { signOut } from 'actions/Auth';

class UserInfo extends React.Component {
	state = {
		anchorEl: null,
		open: false,
	};

	handleClick = event => {
		this.setState({ open: true, anchorEl: event.currentTarget });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { authUser } = this.props;
		return (
			<div className="user-profile d-flex flex-row align-items-center">
				<Avatar
					alt="LivUp Logo"
					src="http://via.placeholder.com/150x150"
					className="user-avatar"
				/>
				<div
					className="user-detail"
					onClick={this.handleClick}
					onKeyDown={this.handleClick}
					role="button"
					tabIndex={0}
				>
					<h4 className="user-name">
						{authUser?.name}{' '}
						<i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
					</h4>
				</div>
				<Menu
					className="user-info"
					id="simple-menu"
					anchorEl={this.state.anchorEl}
					open={this.state.open}
					onClose={this.handleRequestClose}
					PaperProps={{
						style: {
							width: 120,
							paddingTop: 0,
							paddingBottom: 0,
						},
					}}
				>
					<MenuItem
						onClick={() => {
							this.handleRequestClose();
							this.props.signOut();
						}}
					>
						<i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

						<IntlMessages id="popup.logout" />
					</MenuItem>
				</Menu>
			</div>
		);
	}
}

const mapStateToProps = ({ settings, auth }) => {
	const { locale } = settings;
	const { authUser } = auth;
	return { locale, authUser };
};
export default connect(mapStateToProps, { signOut })(UserInfo);
